<template>
  <div class="modal fade" ref="mdlFiltros" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Filtros
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 mb-3">
              <div class="input-group">
                <span class="input-group-text">
                  Prioridad
                </span>
                <select
                  name="prioridad"
                  class="form-select"
                  v-model="filtros.prioridad"
                  @change="filtrosActualizado()"
                >
                  <option :value="null">Seleccionar</option>
                  <option :value="3">Normal</option>
                  <option :value="2">Media</option>
                  <option :value="1">URGENTE</option>
                </select>
              </div>
            </div>
            <div class="col-lg-8 col-md-6 col-sm-6 text-right">
              <div class="form-check form-switch form-switch-lg mb-3" style="display: inline-block">
                <input
                  :checked="filtros.solo_nuevos"
                  @change="
                  filtros.solo_nuevos = !filtros.solo_nuevos,
                    filtrosActualizado()
                  "
                  class="form-check-input"
                  type="checkbox"
                  id="soloNuevos"
                />
                <label
                  class="form-check-label"
                  for="soloNuevos"
                >
                  Nuevos
                </label>
              </div>
            </div>
          </div>

          <div class="pt-2 mb-2">
            <div class="form-check" style="display: inline-block;">
              <input
                class="form-check-input form-check-input-success"
                v-model="filtros.tod_act_comp" :value="'todos'" 
                type="radio" name="todActComp" id="todos"
                @change="filtrosActualizado()"
              />
              <label class="form-check-label" for="todos" :class="{'text-success': filtros.tod_act_comp == 'todos'}">
                <strong>Todos</strong>
              </label>
            </div>
            &nbsp;&nbsp;
            <div class="form-check" style="display: inline-block;">
              <input
                class="form-check-input form-check-input-warning"
                v-model="filtros.tod_act_comp" :value="'activos'" type="radio" 
                name="todActComp" id="activos"
                @change="filtrosActualizado()"
              />
              <label class="form-check-label" for="activos" :class="{'text-warning': filtros.tod_act_comp == 'activos'}">
                <strong>Activos</strong>
              </label>
            </div>
            &nbsp;&nbsp;
            <div class="form-check" style="display: inline-block;">
              <input
                class="form-check-input form-check-input-primary"
                v-model="filtros.tod_act_comp" :value="'completados'" 
                type="radio" name="todActComp" id="completados"
                @change="filtrosActualizado()"
              />
              <label class="form-check-label" for="completados" :class="{'text-primary': filtros.tod_act_comp == 'completados'}">
                <strong>Completados</strong>
              </label>
            </div>
            &nbsp;&nbsp;
            <div class="form-check" style="display: inline-block;">
              <input
                class="form-check-input form-check-input-danger"
                v-model="filtros.tod_act_comp" :value="'cancelados'" 
                type="radio" name="todActComp" id="cancelados"
                @change="filtrosActualizado()"
              />
              <label class="form-check-label" for="cancelados" :class="{'text-danger': filtros.tod_act_comp == 'cancelados'}">
                <strong>Cancelados</strong>
              </label>
            </div>
          </div>

          <hr>
          <h5>Campos de busqueda</h5>

          <div class="row">
            <div class="col-md-6 col-sm-6 col-6" v-for="filtro, index in filtros.campos" :key="index">
              <div class="form-check">
                <input
                  class="form-check-input form-check-input-success"
                  :id="filtro.columna" type="checkbox" :checked="filtro.activo"
                  @change="
                    filtro.activo = !filtro.activo,
                    filtrosActualizado()
                  "
                />
                <label
                  class="form-check-label"
                  :class="{'text-success': filtro.activo}"
                  :for="filtro.columna"
                >
                  <strong>{{filtro.nombre}}</strong>
                </label>
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-light" @click="limpiar()">
                Limpiar
              </button>
              <button class="btn btn-light ms-2" @click="todos()">
                Todos
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'CmpMdlSeleccionCpe',
  data() {
    return {
      filtros: {
        prioridad: null,
        tod_act_comp: 'activos',
        solo_nuevos: false,
        campos: [
          {
            activo: true,
            nombre: 'ID',
            columna: 'id'
          },
          {
            activo: true,
            nombre: 'ID Cliente',
            columna: 'id_cliente'
          },
          {
            activo: true,
            nombre: 'Nombre',
            columna: 'nombre'
          },
          {
            activo: false,
            nombre: 'Nota soporte',
            columna: 'nota_soporte'
          },
          {
            activo: false,
            nombre: 'Nota cliente',
            columna: 'nota_cliente'
          },
          {
            activo: true,
            nombre: 'Numero de cliente',
            columna: 'numero_cliente'
          },
          {
            activo: true,
            nombre: 'Correo',
            columna: 'correo'
          },
          {
            activo: false,
            nombre: 'Fecha',
            columna: 'created_at'
          }
        ]
      }
    }
  },
  methods: {
    mostrar(filtros) {
      var self = this
      self.filtros = filtros

      var modal = new Modal(this.$refs.mdlFiltros)
      modal.show()
    },
    filtrosActualizado: function() {
      this.$emit('filtros-actualizados', this.filtros)
      // var modal = Modal.getInstance(this.$refs.mdlFiltros)
      // modal.hide()
    },
    limpiar: function() {
      var self = this

      self.filtros.campos.forEach(filtro => {
        filtro.activo = false
      })
      self.filtrosActualizado()
    },
    todos: function() {
      var self = this
      self.filtros.campos.forEach(filtro => {
        filtro.activo = true
      })
      self.filtrosActualizado()
    }
  }
}
</script>

<style scoped>
.form-check-input-success:checked {
  background-color: #63AD6F;
  color: #63AD6F;
  border-color: #63AD6F;
}
.form-check-input-primary:checked {
  background-color: #3B76E1;
  border-color: #3B76E1;
}
.form-check-input-warning:checked {
  background-color: #F9C256;
  border-color: #F9C256;
}
.form-check-input-danger:checked {
  background-color: #F56E6E;
  border-color: #F56E6E;
}
</style>